<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="dialog-xl"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <q-card-section
          :class="isMobile ? 'q-px-none q-pt-lg q-pb-md' : 'q-pa-xl'"
        >
          <div class="container">
            <div class="row q-col-gutter-md">
              <div class="col-12 col-md-12 text-center">
                <h6 class="q-ma-none text-dark">Attention Required!</h6>
                <p class="q-ma-none text-subtitle2">
                  The information you entered during age verification does not
                  match the information you have under your
                  {{ businessConfigurations.businessName }} account.
                </p>
              </div>
              <div class="col-12 col-md-12">
                <div class="row q-col-gutter-md items-start">
                  <div class="col-6">
                    <div
                      class="items-center text-center text-subtitle1 text-green q-mb-sm line-height-normal"
                      :class="isMobile ? 'column' : 'row justify-center'"
                    >
                      <SvgIcon
                        size="1.5rem"
                        icon="verify-success"
                        class="q-ma-sm"
                      />
                      Details verified by Age Verification System
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="items-center text-center text-subtitle1 text-red q-mb-sm line-height-normal"
                      :class="isMobile ? 'column' : 'row justify-center'"
                    >
                      <SvgIcon
                        size="1.5rem"
                        icon="verify-error"
                        class="q-ma-sm"
                      />
                      Details found under your
                      {{ businessConfigurations.businessName }} account
                    </div>
                  </div>
                </div>
                <div class="row q-col-gutter-md items-start">
                  <div class="col-6" v-if="properties.personalInfo">
                    <div class="b-1 b-green q-pa-xs">
                      <div class="row q-col-gutter-xs">
                        <div class="col-12 col-sm-6">
                          <div class="bg-grey-1 q-pa-sm">
                            <div
                              class="text-uppercase text-caption text-weight-bold text-dark"
                            >
                              First Name
                            </div>
                            <div class="text-body1 ellipsis">{{
                              properties.personalInfo.firstName || '-'
                            }}</div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="bg-grey-1 q-pa-sm">
                            <div
                              class="text-uppercase text-caption text-weight-bold text-dark"
                              >Last Name</div
                            >
                            <div class="text-body1 ellipsis">{{
                              properties.personalInfo.lastName || '-'
                            }}</div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="bg-grey-1 q-pa-sm">
                            <div
                              class="text-uppercase text-caption text-weight-bold text-dark"
                              >Date of Birth</div
                            >
                            <div
                              class="text-body1 ellipsis"
                              v-text="
                                $options.filters.formatDate(
                                  properties.personalInfo.dob,
                                  'MM/DD/YYYY'
                                ) || '-'
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" v-if="properties.preFillInformation">
                    <div class="b-1 b-red q-pa-xs">
                      <div class="row q-col-gutter-xs">
                        <div class="col-12 col-sm-6">
                          <div class="bg-grey-1 q-pa-sm">
                            <div
                              class="text-uppercase text-caption text-weight-bold"
                              :class="{
                                'text-red':
                                  properties.mismatchedFieldName.includes(
                                    'firstName'
                                  ),
                              }"
                            >
                              First Name
                            </div>
                            <div class="text-body1 ellipsis">{{
                              properties.preFillInformation.firstName || '-'
                            }}</div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="bg-grey-1 q-pa-sm">
                            <div
                              class="text-uppercase text-caption text-weight-bold text-dark"
                              :class="{
                                'text-red':
                                  properties.mismatchedFieldName.includes(
                                    'lastName'
                                  ),
                              }"
                            >
                              Last Name
                            </div>
                            <div class="text-body1 ellipsis">{{
                              properties.preFillInformation.lastName || '-'
                            }}</div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="bg-grey-1 q-pa-sm">
                            <div
                              class="text-uppercase text-caption text-weight-bold text-dark"
                              :class="{
                                'text-red':
                                  properties.mismatchedFieldName.includes(
                                    'dob'
                                  ),
                              }"
                            >
                              Date of Birth
                            </div>
                            <div
                              class="text-body1 ellipsis"
                              v-text="
                                $options.filters.formatDate(
                                  properties.preFillInformation.dob,
                                  'MM/DD/YYYY'
                                ) || '-'
                              "
                            >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 text-center">
                <p class="q-ma-none text-subtitle2"
                  >To continue finishing age verification, hit Update &
                  Continue. This will update your
                  {{ businessConfigurations.businessName }} account with the
                  details verified by the age verification system.</p
                >
              </div>
              <div class="col-12 col-md-6 offset-md-3">
                <div class="row q-col-gutter-md">
                  <div class="col-6">
                    <div class="form-action">
                      <q-btn
                        unelevated
                        no-caps
                        color="tertiary"
                        label="Cancel"
                        class="full-width"
                        padding="8px 0"
                        :loading="cancelBtnLoading"
                        @click="cancelInfo"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-action">
                      <q-btn
                        unelevated
                        no-caps
                        color="secondaryOnBody"
                        label="Update & Continue"
                        class="full-width"
                        padding="8px 0"
                        :loading="loading"
                        @click="updateInfo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions class="bg-grey-2">
          <div class="copyright full-width text-center">
            Powered by
            <q-img src="images/ftx-identity.svg" width="80px" height="35px" />
          </div>
        </q-card-actions>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

function initialState() {
  return {
    dialog: 'MismatchPersonalInfo',
    cancelBtnLoading: false,
    continueBtnLoading: false,
  }
}
export default {
  name: 'MismatchPersonalInfo',
  data: function () {
    return initialState()
  },
  computed: {
    ...mapGetters('ageVerification', ['loading']),
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  methods: {
    async cancelInfo() {
      this.cancelBtnLoading = true
      if (this.properties?.onCancel) {
        await this.properties.onCancel(this.eaivPersistedSessionId)
        this.cancelBtnLoading = false
      }
    },
    async updateInfo() {
      if (this.properties?.onContinue) {
        await this.properties.onContinue(
          this.eaivPersistedSessionId,
          this.$route.name
        )
      }
    },
  },
}
</script>
